import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout/layout';
import { Dropdown } from '../components/layout/dropdown';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import get from 'lodash/get';
import styled from '@emotion/styled';

const FaqList = styled.div`
  width: 100%;
  height: auto;
  padding: 0 calc((100vw - 1000px) / 2);
  @media (max-width: 768px) {
    padding: 5px 20px;
  }
`;

const Caption = styled.span`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
`;

const Flex = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  opacity: 100%;
  padding: 30px 100px;
  margin-top: 20px;
  @media (max-width: 768px) {
    padding: 5px 20px;
  }
`;

const Text = styled.span`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0px;
`;

const PageTitle = styled.h1`
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #084f87;
  margin: 20px 0 30px;
`;

const PhoneLink = styled(OutboundLink)`
  all: unset;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  :hover {
    color: #084f87;
  }
`;
const Details = styled(Link)`
  color: #084f87;
  cursor: pointer;
`;

class FirstVisit extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulFirstVisitBox.edges');
    return (
      <Layout location={this.props.location}>
        <FaqList>
          <PageTitle>Information for your first visit:</PageTitle>
          <Caption>
            If you have any additional questions and concerns about your visit
            to our clinic, please do not hesitate to contact us at
            <PhoneLink href="tel:1-604-455-7772">
              &nbsp;1-604-455-7772
            </PhoneLink>{' '}
            or send us a message <Details to="/contact/">here</Details>.
          </Caption>
          <Flex>
            {posts.map(({ node }) => {
              return (
                <Dropdown type={'text'} openText={node.faqQuestion}>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: node.faqAnswer.childMarkdownRemark.html,
                    }}
                  />
                </Dropdown>
              );
            })}
          </Flex>
        </FaqList>
      </Layout>
    );
  }
}

export default FirstVisit;

export const PageQuery = graphql`
  query {
    allContentfulFirstVisitBox {
      edges {
        node {
          orderId
          faqQuestion
          faqAnswer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
